import React from 'react';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {Form} from 'react-bootstrap';
import {matchPath, useLocation, useSearchParams} from 'react-router-dom';
import {useConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {genericMessageHandler} from 'shared/helpers';
import {PartialRoom} from 'shared/types/PartialRoom';
import {OptionsType} from 'shared/types';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {PartialJob} from 'shared/types/PartialJob';
import {
    useDeleteRoomMutation,
    useCopyRoomMutation,
} from 'components/customer/Room/store/roomApi';
import {RoomCost} from 'shared/components/Header/helper/RoomCost';

export const useRoomsSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {notify} = useNotificationContext();
    const {
        job,
        room,
        isPageDirty,
        setIsPageDirty,
        actionButtonsEnabled,
        expiredJobPrice,
        setRoom,
    } = useJobContext() as PartialJob;
    const {displayId: jobId} = job;

    const [, setSearchParams] = useSearchParams();

    const deleteRoomMessage = `Are you sure you want to delete this Room?`;
    const {showDialog, dialog} = useConfirmationDialog();
    const {userProfile} = useAppContext();

    const [deleteRoom] = useDeleteRoomMutation();
    const [copyRoom] = useCopyRoomMutation();

    const handleRoomChange = (selectedRoom: PartialRoom) => {
        const redirect = !!matchPath(
            '/v2/job/:jobId/room/:roomId/*',
            location.pathname
        );

        if (redirect) {
            const pathname = location.pathname.replace(
                `room/${room.id}`,
                `room/${selectedRoom.id}`
            );

            navigate(pathname, {replace: true});
        } else {
            setRoom(selectedRoom);
        }
    };

    const deleteRoomHandler = (
        row: PartialRoom,
        event: React.MouseEvent<HTMLElement>
    ) => {
        const button = event.currentTarget.parentElement as HTMLButtonElement;
        showDialog({
            title: 'Delete Room',
            message: deleteRoomMessage,
            yes: async () => {
                // You can make this function async so that function code is much more clearer
                button.disabled = true;
                try {
                    await deleteRoom({
                        id: row.id,
                        jobId: row.jobId,
                    });

                    setSearchParams({'deleted-room': String(row.id)});
                    genericMessageHandler(
                        notify,
                        {message: 'Room deleted'},
                        'success'
                    );
                    button.disabled = false;
                } catch (error) {
                    genericMessageHandler(notify, {
                        message:
                            'Could not delete the room right now, Please try again later.',
                    });
                }
            },
        });
    };

    const copyRoomHandler = (
        row: PartialRoom,
        event: React.MouseEvent<HTMLElement>,
        icon: string,
        setIcon: (icon: string) => void
    ) => {
        const ogIcon = icon;
        const button = event.currentTarget.parentElement as HTMLButtonElement;
        const hasProduct = row.jobBenchtops.length > 0 || row.productCount > 0;
        const copyMessage = hasProduct
            ? 'Would you like to also copy the current products of this room?'
            : `Are you sure you want to copy this room?`;

        showDialog({
            title: 'Copy Room',
            message: copyMessage,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    alignLeft: true,
                    name: 'Cancel',
                    variant: 'warning',
                    show: hasProduct,
                },
                {
                    show: true,
                    name: 'No',
                    action: async () => {
                        button.disabled = true;
                        if (hasProduct) {
                            setIcon('tail-spin.svg');
                            try {
                                await copyRoom({
                                    jobId: row.jobId,
                                    id: row.id,
                                    copyProducts: false,
                                });
                                genericMessageHandler(
                                    notify,
                                    {message: 'Room successfully copied.'},
                                    'success'
                                );
                                setIsPageDirty([]);
                            } catch (error) {
                                genericMessageHandler(notify, error);
                            } finally {
                                setIcon(ogIcon);
                                button.disabled = false;
                            }
                        } else {
                            button.disabled = false;
                        }
                    },
                },
                {
                    show: true,
                    name: 'Yes',
                    variant: 'primary-color',
                    action: async () => {
                        button.disabled = true;
                        setIcon('tail-spin.svg');
                        try {
                            await copyRoom({
                                jobId: row.jobId,
                                id: row.id,
                                copyProducts: true,
                            });
                            genericMessageHandler(
                                notify,
                                {message: 'Room successfully copied.'},
                                'success'
                            );

                            setIsPageDirty([]);
                        } catch (error) {
                            genericMessageHandler(notify, error);
                        } finally {
                            setIcon(ogIcon);
                            button.disabled = false;
                        }
                    },
                },
            ],
        });
    };

    const tableConfig: OptionsType = {
        fields: [
            {
                fieldName: 'name',
                title: 'Current Rooms',
                format: function RoomCheckBox(value, room_) {
                    return (
                        <div
                            className="cbc-radio"
                            onClick={() => {
                                if (room_.id != room.id) {
                                    handleRoomChange(room_);
                                }
                            }}>
                            <Form.Check
                                style={{cursor: 'pointer'}}
                                type="radio"
                                id={`selectedRoom_${room_.id}`}
                                name="selectedRoom"
                                value={room_.id}
                                checked={room_.id === room.id}
                                label={value}
                                onChange={() => handleRoomChange(room_)}
                            />
                        </div>
                    );
                },
            },
            {
                fieldName: 'name',
                title: 'Items',
                width: 80,
                format: function formatRoomName(value, room_) {
                    return (
                        <div onClick={() => handleRoomChange(room_)}>
                            {' '}
                            {room_.productCount}{' '}
                        </div>
                    );
                },
            },
            userProfile.show_pricing && {
                fieldName: 'name',
                title: 'Cost',
                width: 65,
                format: function formatCost(value, room_) {
                    return (
                        <div
                            style={
                                !actionButtonsEnabled || job.status == 5
                                    ? {textAlign: 'center'}
                                    : {}
                            }
                            onClick={() => handleRoomChange(room_)}>
                            <RoomCost roomId={room_.id} job={job} />
                        </div>
                    );
                },
            },
        ],
        data: job.rooms,
        hasCursor: true,
        noRecordsText: `No Rooms`,
    };

    if (actionButtonsEnabled && job.status != 5) {
        tableConfig.options = {
            title: '',
            width: 75,
            buttons: [
                {
                    iconName: 'Options-Edit.svg',
                    title: 'Edit Room',
                    action: (row) => {
                        navigate(`/v2/job/${jobId}/room/${row.id}`, {
                            state: {room: row},
                        });
                    },
                },
                {
                    iconName: 'Options-Copy.svg',
                    title: 'Copy Room',
                    action: copyRoomHandler,
                    confirmChanges: isPageDirty.length > 0,
                },
                {
                    iconName: 'Options-Delete.svg',
                    title: 'Delete Room',
                    action: deleteRoomHandler,
                    confirmChanges: isPageDirty.length > 0,
                },
            ],
        };
    }

    if (expiredJobPrice) {
        tableConfig.options = undefined;
    }

    return {
        tableConfig,
        dialog,
    };
};
