import React, {useCallback, useMemo, useState} from 'react';
import {
    Modal,
    Button,
    DropdownButton,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {Icon} from 'shared/helpers';

import styled from 'styled-components';

type ConfirmationButton = {
    title?: string;
    name?: string;
    variant?: string;
    action?: () => void | Promise<void>;
    show?: boolean;
    controlledHideDialog?: boolean;
    disabled?: boolean;
    alignLeft?: boolean;
    iconName?: string;
    isLoading?: boolean;
    options?: {
        name?: string;
        action?: () => void | Promise<void>;
    }[];
};

export type ConfirmationOption = {
    title?: string;
    message?: string | JSX.Element;
    container?: string;
    className?: string;
    hideFooter?: boolean;
    hideYesButton?: boolean;
    yes?: () => void | Promise<void>;
    hideNoButton?: boolean;
    no?: () => void | Promise<void>;
    buttons?: ConfirmationButton[];
    keyboard?: boolean;
    setShow?: boolean;
    modalDialogClass?: string;
    size?: 'sm' | 'lg' | 'xl';
    maxWidth?: number;
};

const Confirmation = {
    YES: true,
    NO: false,
};

interface useConfirmationDialogReturnProps {
    confirmed: boolean;
    setConfirmed: (confirmed: boolean) => void;
    dialog: JSX.Element;
    showDialog: (options: ConfirmationOption) => void;
    hideDialog: () => void;
    setTitle: (title: string) => void;
    setMessage: (message: string | JSX.Element) => void;
}

export const useConfirmationDialog = (
    title_?: string,
    message_?: string,
    backdrop: 'static' | boolean = 'static'
): useConfirmationDialogReturnProps => {
    const [confirmed, setConfirmed] = useState<boolean>(Confirmation.NO);
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState<ConfirmationOption>({});
    const [title, setTitle] = useState<string>(title_ ? title_ : '');
    const [message, setMessage] = useState<string | JSX.Element>(
        message_ ? message_ : ''
    );

    const showDialog = (options: ConfirmationOption) => {
        if (typeof options !== 'undefined') {
            setOptions(options);

            if (options.title) setTitle(options.title);
            if (options.message) setMessage(options.message);
        }

        setShow(true);
    };

    const hideDialog = useCallback(() => {
        setShow(false);
    }, []);

    const dialog = useMemo(
        () => (
            <ConfirmationDialog
                title={title}
                message={message}
                show={show}
                setShow={setShow}
                setConfirmed={setConfirmed}
                hideDialog={hideDialog}
                options={options}
                backdrop={backdrop}
            />
        ),
        [title, message, show, options, backdrop]
    );

    return {
        confirmed,
        setConfirmed,
        dialog,
        showDialog,
        hideDialog,
        setTitle,
        setMessage,
    };
};

interface ConfirmationDialogProps {
    title?: string;
    message?: string | JSX.Element;
    show: boolean;
    setShow?: (show: boolean) => void;
    setConfirmed?: (show: boolean) => void;
    hideDialog: () => void;
    options: ConfirmationOption;
    backdrop: boolean | string;
}

export const ConfirmationDialog = ({
    title,
    message,
    show,
    setShow,
    setConfirmed,
    hideDialog,
    options,
    backdrop,
}: ConfirmationDialogProps) => {
    return (
        <ConfirmationDialogElement
            dialogClassName={
                options.hasOwnProperty('modalDialogClass')
                    ? options.modalDialogClass
                    : ''
            }
            show={show}
            onHide={() => {
                setShow && setShow(false);
            }}
            backdropClassName={
                options.hasOwnProperty('container') ? 'absolute-backdrop' : ''
            }
            container={
                options.hasOwnProperty('container')
                    ? document.querySelector<HTMLElement>(options.container)
                    : undefined
            }
            backdrop={backdrop}
            className={
                options.className
                    ? `confirmationDialog ${options.className}`
                    : 'confirmationDialog'
            }
            keyboard={
                options.hasOwnProperty('keyboard') ? options.keyboard : true
            }
            size={options.hasOwnProperty('size') ? options.size : undefined}
            centered={true}
            restoreFocus={false}
            animation={false}
            $maxWidth={options.maxWidth}>
            <Modal.Header>
                <Modal.Title>
                    <strong>{title}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {typeof message === 'object'
                    ? message
                    : parseHtmlString(message)}
            </Modal.Body>
            {options.hasOwnProperty('hideFooter') && options.hideFooter ? (
                <></>
            ) : (
                <Modal.Footer>
                    {options.hasOwnProperty('hideYesButton') &&
                    options.hideYesButton ? (
                        <></>
                    ) : (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setConfirmed && setConfirmed(Confirmation.YES);

                                hideDialog();

                                if (options.yes) options.yes();
                            }}>
                            Yes
                        </Button>
                    )}

                    {options.hasOwnProperty('hideNoButton') &&
                    options.hideNoButton ? (
                        <></>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setConfirmed && setConfirmed(Confirmation.NO);

                                if (options.no) options.no();

                                hideDialog();
                            }}>
                            No
                        </Button>
                    )}

                    {options.buttons
                        ? options.buttons.map((button: ConfirmationButton) => {
                              if (button.show) {
                                  if (button.name != 'moreOptions') {
                                      const buttonControlledHideDialog =
                                          button.hasOwnProperty(
                                              'controlledHideDialog'
                                          ) && button.controlledHideDialog;
                                      const disabled =
                                          button.hasOwnProperty('disabled') &&
                                          button.disabled
                                              ? true
                                              : false;
                                      const isLoading = !!button?.isLoading;
                                      return (
                                          <Button
                                              title={
                                                  button.title
                                                      ? button.title
                                                      : button.name
                                              }
                                              key={button.name}
                                              variant={
                                                  button.hasOwnProperty(
                                                      'variant'
                                                  )
                                                      ? button.variant
                                                      : 'primary'
                                              }
                                              onClick={() => {
                                                  if (
                                                      !buttonControlledHideDialog
                                                  )
                                                      hideDialog();

                                                  if (button.action)
                                                      button.action();
                                              }}
                                              style={{
                                                  ...(button.hasOwnProperty(
                                                      'alignLeft'
                                                  ) && button.alignLeft
                                                      ? {marginRight: 'auto'}
                                                      : {}),
                                                  ...(button.hasOwnProperty(
                                                      'iconName'
                                                  )
                                                      ? {
                                                            padding:
                                                                '0 10px 0 10px',
                                                        }
                                                      : {}),
                                              }}
                                              disabled={disabled || isLoading}>
                                              {button.hasOwnProperty(
                                                  'iconName'
                                              ) ? (
                                                  <JoinIcon
                                                      iconName={button.iconName}
                                                      color="#ffffff"
                                                  />
                                              ) : null}
                                              {button.hasOwnProperty('name')
                                                  ? button.name
                                                  : 'Button'}
                                              {isLoading ? (
                                                  <Spinner
                                                      style={{
                                                          width: '14px',
                                                          height: '14px',
                                                          marginLeft: '4px',
                                                      }}
                                                      animation="border"
                                                      role="status">
                                                      <span className="visually-hidden">
                                                          Loading...
                                                      </span>
                                                  </Spinner>
                                              ) : null}
                                          </Button>
                                      );
                                  } else {
                                      return (
                                          <DropdownButton
                                              key={button.name}
                                              align="end"
                                              title="More Options"
                                              className="jobStatusDropdown"
                                              id="dropdown-menu-align-right">
                                              {button.options
                                                  ? button.options.map(
                                                        (option) => {
                                                            return (
                                                                <StyledDropDownItem
                                                                    key={
                                                                        option.name
                                                                    }
                                                                    onClick={
                                                                        option.action
                                                                    }>
                                                                    {
                                                                        option.name
                                                                    }
                                                                </StyledDropDownItem>
                                                            );
                                                        }
                                                    )
                                                  : null}
                                          </DropdownButton>
                                      );
                                  }
                              }
                          })
                        : null}
                </Modal.Footer>
            )}
        </ConfirmationDialogElement>
    );
};

const StyledDropDownItem = styled(Dropdown.Item)`
    padding: 8px 13px !important;
    &:hover {
        background-color: rgba(var(--menu_primary_colour), 0.85) !important;
    }
`;

const JoinIcon = styled(Icon)`
    width: 30px;
    height: 30px;
    margin-right: 5px;
`;

const ConfirmationDialogElement = styled(Modal)<{$maxWidth?: number}>`
    .btn-primary {
        background: ${({theme}) => theme?.colors?.secondary?.main};
        border-color: ${({theme}) => theme?.colors?.secondary?.main};
    }
    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:active {
        background: ${({theme}) => theme?.colors?.primary?.main} !important;
        border-color: ${({theme}) => theme?.colors?.primary?.main} !important;
    }

    .btn-primary-color {
        background: ${({theme}) => theme?.colors?.primary?.main};
        border-color: ${({theme}) => theme?.colors?.primary?.main};
        color: white;
    }
    .btn-primary-color:hover,
    .btn-primary-color:focus,
    .btn-primary-color:active {
        background: ${({theme}) => theme?.colors?.secondary?.main};
        border-color: ${({theme}) => theme?.colors?.secondary?.main};
        color: white;
    }

    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:focus,
    .btn-secondary:active {
        background: #c92434;
        border-color: #c92434;
    }

    > .modal-dialog {
        ${({$maxWidth}) => ($maxWidth ? `max-width: ${$maxWidth}px;` : '')}
    }
`;
