import {useGetRoomProductsQuery} from 'components/customer/Product/store/productApi';
import React, {useMemo} from 'react';
import {formatPrice} from 'shared/helpers';
import {Job} from 'shared/types/PartialJob';

interface RoomCostProps {
    roomId: number;
    job: Job;
}

export const RoomCost = ({roomId, job}: RoomCostProps) => {
    const {data: products} = useGetRoomProductsQuery(
        {roomId: Number(roomId)},
        {
            skip: typeof roomId === 'undefined',
        }
    );

    const currentRoomCost = useMemo(() => {
        if (products) {
            const total = products.reduce((acc, product) => {
                return acc + product.cost;
            }, 0);

            return total;
        }
    }, [products]);

    return <>{formatPrice(currentRoomCost, job)}</>;
};
